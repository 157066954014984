import React, { useState, useEffect } from 'react';
import { Input, Button, Drawer } from 'antd';
import { ControlOutlined } from '@ant-design/icons';

import config from 'config'
import AdvancedFilter from 'components/advancedFilter/AdvancedFilter';

const { Search } = Input;

const styles = {
  searchBar: {
    width: '320px',
    height: '36px',
    borderRadius: '4px',
    textAlign: 'left'
  }
}

export default function Filter({ title, onChange, initial, extension, audio }) {

  if (!initial) initial = config.filter;
  if (initial.substring(1, 1) === '=') initial = initial.substring(2);

  const [showView, setShowView] = useState(false);
  const [type, setType] = useState('');
  const [titel, setTitle] = useState('');

  const handleSearch = value => {
    config.filter = value;
    onChange();
  }

  const openDrawer = () => {
    if (title !== undefined) {
      setTitle(title + ': Advanced Filter');
      setType(title.substring(0, 1));
      setShowView(true);
    }
  }

  useEffect(() => {
    if (title !== undefined) {
      setType(title.substring(0, 1));
    };
    if (config.filter.substring(1, 1) === '=') config.filter = config.filter.substring(2);
  }, [title]);

  const component = () => <AdvancedFilter filterType={type} onChangeTitle={onChangeTitle} />

  const onChangeTitle = tit => setTitle(`${title}: Advanced Filter (${tit})`);

  return (
    <div style={{ textAlign: 'right', margin: '16px 0' }}>
      <Input.Group compact>
        <span style={{ marginTop: 6, marginRight: 6 }}>Search:&nbsp;</span>
        <Search onSearch={handleSearch} allowClear style={styles.searchBar} defaultValue={initial} />
        {title !== undefined && !extension && (<>
          <Button className='filterButton' style={{ margin: '0 12px', height: 36, width: 124 }} onClick={openDrawer}><ControlOutlined /> Filter</Button>
          {/* {!!audio && (<Button type='primary' htmlType="button"
            className='filterButton'
            onClick={audio}
            style={{ margin: '0 12px', height: 36, width: 164 }}>
            Filter for audio files
          </Button>)} */}
        </>)}
      </Input.Group>
      <Drawer
        title={<h2>{titel}</h2>}
        width={'80%'}
        placement="right"
        onClose={() => setShowView(false)}
        destroyOnClose={true}
        open={showView}>
        {component()}
      </Drawer>
    </div>
  )
}