import React, { useState, useEffect, useCallback } from "react";
import { Form, Layout, Input, Button, message, Modal } from "antd";
import Password from "./Password";

import config from "config";
import logobw from "assets/images/logo_wl.png";
import inverse from "assets/images/inverse.png";
import service from "services/apiServices";
import { clearToken, getToken, setToken } from "services/authServices";
import apiServices from "services/apiServices";

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } }

const styles = {
  logo: {
    width: "104px",
    height: "222px"
  },
  error: {
    backgroundColor: "red",
    color: "white",
    fontSize: "1.2rem",
    margin: "24px auto",
    padding: "12px"
  },
  linkColor: {
    color: "black",
  },
  center: {
    textAlign: "center",
  },
  name: {
    marginLeft: "20px",
  },
  fields: {
    backgroundColor: " #0D0B0B",
    verticalAlign: "middle",
  }
};

const whiteLabel = {
  color: 'white',
  paddingTop: '16px',
  textAlign: 'right'
};

const Curtain = () => {
  return (
    <div className="curtain">
      <div className="circle" />
    </div>
  );
};

const Login = ({ history }) => {

  const [form] = Form.useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");

  async function fetch(credentials) {
    const result = await service.auth(credentials);
    return result;
  }

  const getMaster = async () => {
    const cs = await service.list("conflicts");
    if (cs) {
      config.conflicts = cs;
    }
    const ps = await service.list("participants");
    if (ps) {
      config.participants = ps;
    }
    const ss = await service.list("stories");
    if (ss) {
      config.stories = ss;
    }
  }

  const setCurrent = useCallback((result, remember) => {
    config.currentUser = {
      id: result.id,
      name: result.name,
      role: result.role,
      landing: result.landing,
      conflict: result.conflict,
      canDelete: result.role === "SuperAdmin",
      delParticipants: result.delParticipants || result.role === "SuperAdmin",
      delStories: result.delStories || result.role === "SuperAdmin",
      delObjects: result.delObjects || result.role === "SuperAdmin",
      delParts: result.delParts || result.role === "SuperAdmin",
      delHistories: result.delHistories || result.role === "SuperAdmin"
    };
    
    config.token = result.token;
    setToken(result.token);
    if (config.attemptRoute !== '') {
      history.push(config.attemptRoute);
      config.attemptRoute = '';
    } else {
      history.push(result.landing);
    };
    getMaster();
  }, [history]);

  useEffect(() => {
    let isCancelled = false;
    const checkToken = async () => {
      setLoading(true);
      try {
        if (!isCancelled) {
          const token = await getToken();
          if (token && token !== "undefined" && token !== null) {
            const credentials = {
              username: "token",
              password: token,
              remember: true,
            };
            let result = await fetch(credentials);
            if (result) {
              setCurrent(result);
            } else {
              clearToken();
              setLoading(false);
            }
          } else {
            isCancelled = true;
            setLoading(false);
          }
        }
      } catch (error) {
        if (!isCancelled) {
          console.error(error);
        }
      }
    }
    checkToken();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async (values) => {
    setLoading(true);
    let result = await fetch({ ...values });
    if (result) {
      if (result.password === "change-me") {
        setUserId(result.id);
        setUserName(result.firstName + " " + result.lastName);
        return setPassVisible(true);
      }
      if (result.token) {
        setCurrent(result, values.remember);
      } else {
        message.info('PIN required');
        setModalVisible(true);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError("Invalid credentials! Try again!");
    };
  };

  const onFinishFailed = (errorInfo) => {
    console.loerrorg("Failed:", errorInfo);
  };

  const closePin = () => {
    setModalVisible(false);
    setLoading(false)
  }

  const closePass = () => {
    setPassVisible(false);
    setLoading(false)
  }

  const submitPin = async () => {
    closePin();
    const data = {
      username: 'pin',
      password: form.getFieldValue('pin'),
      remember: form.getFieldValue('remember')
    }
    let result = await fetch(data);
    if (result) setCurrent(result, form.getFieldValue('remember'));
  }

  const submitPass = async values => {
    let result = await apiServices.update("setpass", values, userId);
    if (result) {
      message.success("PASSWORD SET SUCCESSFULLY!");
      closePass();
    } else {
      message.error("ERROR SETTING PASSWORD!");
    }
  }

  return (
    <div>
      {loading && <Curtain />}
      <Layout className="bgLogIn">
        <img src={inverse} alt="inverse" style={{width:'40vw', margin: '48px auto'}} />
        <div className="outer">
          {error && <div style={styles.error}>{error}</div>}
          <div className="inner">
            <img src={logobw} alt="logo" style={styles.logo} />
            <div style={{ paddingTop: '18px' }}>
              <Form form={form} {...layout}
                name="loginForm"
                onFinish={handleSubmit}
                onFinishFailed={onFinishFailed}>
                <Form.Item label={<p style={whiteLabel}>Username: </p>} name="username"><Input autoFocus /></Form.Item>
                <Form.Item label={<p style={whiteLabel}>Password: </p>} name="password"><Input type="password" /></Form.Item>
                <div className="link">
                  <a href="/reset" className="head-link">FORGOT PASSWORD? Click here</a>
                </div>

                <div className="fieldPlacement">
                  <Form.Item>
                    <Button
                      className="buttonLogIn"
                      type="primary"
                      htmlType="submit">
                      SIGN IN
                    </Button>
                  </Form.Item>
                </div>

                <Modal title='VERIFICATION CODE REQUIRED' open={modalVisible} centered width="36rem"
                  footer={[
                    <Button key='c' type='primary' onClick={closePin}>Close</Button>,
                    <Button key='s' type='primary' onClick={submitPin}>Submit</Button>
                  ]}>
                  <p>Dear user,</p>
                  <p>We just sent a verification code to your email.</p>
                  <p>Please check your mail and enter the received code into the appropriate field below.<br />
                    You have to use the verification code during next 15 minutes.</p>
                  <p>If you are having any issues with your account please don’t hesitate to contact us by replying to this mail.</p>
                  <hr />
                  <Form.Item name="pin" label="Verification code"><Input /></Form.Item>
                </Modal>

                <Modal title={<h2>SET YOUR PASSWORD</h2>} open={passVisible} onCancel={closePass} centered width="50%" footer={[]}>
                  <Password name={userName} onClose={closePass} onSubmit={submitPass} />
                </Modal>
              </Form>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Login;